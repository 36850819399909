<template>
  <b-container class="budget-multiple-accounts-table-incomes-expenses px-0">
    <b-row class="bordered-table mt-3 px-0 depth-1" no-gutters>
      <b-col cols="12">
        <b-row class="mx-4">
          <b-col cols="12" md="9">
            <h2 v-if="activeTab === 'incomes'" class="text-gray">{{ $t( translationPath + 'table.incomes_title') }}: {{ year }}</h2>
            <h2 v-if="activeTab === 'expenses'" class="text-gray">{{ $t( translationPath + 'table.expenses_title') }}: {{ year }}</h2>
          </b-col>
          <b-col cols="12" md="3" class="text-right pl-0">
            <budget-year-select :options-key="'years'" @input="onYearChanged"></budget-year-select>
          </b-col>
        </b-row>
        <b-row class="mx-4 mb-4">
          <b-col cols="8">
            <div v-if="activeTab === 'incomes'" class="text-regular" v-html="$t( translationPath + 'table.incomes_description')"></div>
            <div v-if="activeTab === 'expenses'" class="text-regular" v-html="$t( translationPath + 'table.expenses_description')"></div>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="">
            <b-row class="dark mb-4" no-gutters>
              <b-col class="pl-3">

                <b-tabs v-model="subTabs" ref="Tabs">

                  <b-tab :title="$t(translationPath + 'tab_create_budget')" @click="handleSubTabsClick('create')" class="mx-2 px-4 mt-4">
                    <b-table
                       class="spirecta-simple-table accounts-table" show-empty hover responsive striped
                       :busy="!isLoaded"
                       :items="tableItems"
                       :fields="fieldValues"
                       :filter="tableFilter"
                       :filter-included-fields="['title']"
                       :tbody-tr-class="rowClass"
                       stacked="md"
                       :emptyText="$t('incomes_expenses.accounts.common.no_data')"
                       ref="budgetTable"
                    >
                      <template v-slot:table-busy><loader/></template>

                      <!-- TOP ROW -->
                      <template slot="top-row">
                        <td colspan="6">
                          <div class="d-flex align-items-center">
                            <i class="fa fa-search"></i>
                            <b-form-input v-model="tableFilter" size="sm" :placeholder="$t( 'incomes_expenses.accounts.common.' + ( currentUser.strict_accounting_mode ? 'account_filter_placeholder' : 'category_filter_placeholder'))" />
                          </div>
                        </td>
                      </template>

                      <!-- HEAD ACTIONS -->
                      <template v-slot:head(actions)>&nbsp;</template>

                      <!-- TITLE -->
                      <template v-slot:cell(title)="row">
                        <template v-if="row.item.is_account === true">
                          <span class="px-2 account_title">&ndash;</span>
                          <b-link :to="'/budget/account/'+row.item.id" class="account_title">{{ row.value | displayAccountTitle( row.item.code, currentCOA.prefix_account_title_with_code ) }}</b-link>
                          <span style="font-size:80%;" class="pl-2">
                            <b-link :to="'/reports/performance/accounts/'+row.item.id+'/view'" :title="$t(translationPath + 'table.btn_open_' + (currentUser.strict_accounting_mode ? 'account' : 'category') + '_report')"><i class="flaticon stroke graph-1"></i></b-link>
                            <b-link href="#" class="pl-2" :title="$t(translationPath + 'table.btn_copy_previous_budget', { year: yearToCopyBudget })" @click.prevent="copyBudget(row.item)"><i class="flaticon stroke copy-1"></i></b-link>
                          </span>
                        </template>
                        <template v-else-if="row.item.is_account_group">
                          <b-link :to="'/reports/performance/account-groups/' + row.item.id" class="account_group_title">{{ row.value | displayAccountTitle( row.item.code, currentCOA.prefix_account_title_with_code ) }}</b-link>
                        </template>
                        <template v-else-if="row.item.is_part_sum_row">
                          {{ $t('common.partsum') }}
                        </template>
                        <template v-else-if="row.item.is_total_row">
                          {{ $t('common.sum') }}
                        </template>
                        <template v-else-if="row.item.is_empty_row"></template>
                        <template v-else></template>
                      </template>

                      <!-- CURRENT YEAR BUDGET -->
                      <template v-slot:cell(currentYearBudget)="row">
                        <template v-if="row.item.is_account">
                          {{ formatAmount(row.item.currentYearBudget) }}
                        </template>
                        <template v-else-if="row.item.is_part_sum_row">
                          {{ formatAmount(row.item.currentAmount ) }}
                        </template>
                        <template v-else-if="row.item.is_total_row">
                          {{ formatAmount(row.item.currentAmount ) }}
                        </template>
                      </template>

                      <!-- PERIOD -->
                      <template v-slot:cell(period)="row">
                        <b-form-select v-if="row.item.is_account"
                          :options="periodOptions"
                          v-model="row.item.updateModel.period"
                          @input="onBudgetChange(row.item)"
                        ></b-form-select>
                      </template>

                      <!-- NEW AMOUNT -->
                      <template v-slot:cell(newAmount)="row">
                        <template v-if="row.item.is_account">
                          <currency-input
                            :id="'new-amount-' + row.item.id"
                            v-model="row.item.updateModel.amount"
                            @input="onBudgetChange(row.item)"
                          />
                        </template>
                      </template>

                      <!-- NEW ANNUAL AMOUNT -->
                      <template v-slot:cell(newAnnualAmount)="row">
                        <template v-if="row.item.is_account">
                          {{ formatAmount(row.item.updateModel.yearAmount ) }}
                        </template>
                        <template v-else-if="row.item.is_part_sum_row">
                          {{ formatAmount(row.item.newAmount ) }}
                        </template>
                        <template v-else-if="row.item.is_total_row">
                          {{ formatAmount(row.item.newAmount ) }}
                        </template>
                      </template>

                      <!-- ACTIONS -->
                      <template v-slot:cell(actions)="row">
                        <template v-if="row.item.is_account">
                          <b-button variant='primary' class='btn-sm mr-2' @click="onSetBudgetAccount(row.item)">{{ $t(translationPath + 'table.btn_set_budget') }}</b-button>
                        </template>
                        <template v-else-if="row.item.is_account_group">
                          <!--<b-button variant='primary' class='btn-sm mr-2' @click="onSetBudgetAccountGroup(row.item)">{{ $t(translationPath + 'table.btn_set_budget_account_group') }}</b-button>-->
                        </template>
                        <template v-else-if="row.item.is_part_sum_row">
                          {{ formatAmount(row.item.newAmount - row.item.currentAmount) }}
                        </template>
                      </template>

                    </b-table>
                    <div class="text-right mr-1">
                      <b-button variant="primary" class="mr-3" @click="onSetBudgetAll">{{ $t(translationPath + 'table.btn_set_budget_all') }}</b-button>
                    </div>
                  </b-tab>

                  <b-tab :title="$t(translationPath + 'tab_advanced_options')" class="mx-2 px-4 mt-4" @click="handleSubTabsClick('options')">

                    <h2>{{$t(translationPath + 'advanced_options.heading')}}</h2>
                    <div class="col-8 pl-0" v-html="$t(translationPath + 'advanced_options.description')"></div>
                    <div class="col-8 alert alert-warning mb-5 pb-0">
                      <p>{{$t(translationPath + 'advanced_options.replacement_warning',{year: this.year})}}</p>
                    </div>

                    <template v-if="budgets.length > 1">
                      <h2>{{ $t(translationPath + 'advanced_options.select_budget_title') }}</h2>
                      <div class="col-8 pl-0" v-html="$t(translationPath + 'advanced_options.select_budget_description')"></div>
                      <b-row class="mt-4">
                        <b-col cols="3" md="3" class="mt-md-1 pt-md-2">
                          {{ $t(translationPath + 'advanced_options.select_budget_title') }}
                        </b-col>
                        <b-col cols="5" md="5" class="pl-0">
                          <b-form-select
                            :options="budgetsOptions"
                            v-model="budgetIdOptionsModel"
                          />
                        </b-col>
                      </b-row>
                      <b-row no-gutters class="mb-4">
                        <b-col class="text-right mt-3" cols="8">
                          <b-button class="mt-2" variant="primary" @click="selectBudget">{{ $t(translationPath + 'table.btn_select_budget') }}</b-button>
                        </b-col>
                      </b-row>
                    </template>

                    <h2>{{$t(translationPath + 'advanced_options.copy_budget_title',{year: this.year})}}</h2>
                    <div class="col-8 pl-0" v-html="$t(translationPath + 'advanced_options.copy_budget_description')"></div>
                    <b-row class="mt-4">
                      <b-col cols="3" md="3" class="mt-md-1 pt-md-2">
                        {{ $t(translationPath + 'table.copy_budget_from_year') }}
                      </b-col>
                      <b-col cols="5" md="5" class="pl-0">
                        <b-form-select v-if="apiYears !== null"
                          :options="apiYears.years_with_existing_budget_values"
                          v-model="yearToCopyBudget"
                        />
                      </b-col>
                    </b-row>
                    <b-row class="mt-4">
                      <b-col cols="3" md="3" class="mt-md-1 pt-md-2">
                        {{ $t(translationPath + 'table.to_year') }}
                      </b-col>
                      <b-col cols="5" md="5" class="pl-0">
                        {{year}}
                      </b-col>
                    </b-row>
                    <b-row no-gutters class="mb-4">
                      <b-col class="text-right mt-3" cols="8">
                        <b-button class="mt-2" variant="primary" :disabled="yearToCopyBudget === year" @click="createBudgetFromAnotherBudgetYear">{{ $t(translationPath + 'table.btn_copy_all_budget', { year_source: yearToCopyBudget, year_target: year }) }}</b-button>
                      </b-col>
                    </b-row>

                    <h2>{{$t(translationPath + 'advanced_options.copy_actual_amounts_title',{year: this.year})}}</h2>
                    <div class="col-8 pl-0" v-html="$t(translationPath + 'advanced_options.copy_actual_amounts_description')"></div>
                    <b-row class="mt-4">
                      <b-col cols="3" md="3" class="mt-md-1 pt-md-2">
                        {{ $t(translationPath + 'table.copy_actual_outcome_from_year') }}
                      </b-col>
                      <b-col cols="5" md="5" class="pl-0">
                        <b-form-select v-if="apiYears !== null"  :options="apiYears.years_with_actual_transaction_values" v-model="yearToActualAmounts" />
                      </b-col>
                    </b-row>
                    <b-row class="mt-4">
                      <b-col cols="3" md="3" class="mt-md-1 pt-md-2">
                        {{ $t(translationPath + 'table.to_year') }}
                      </b-col>
                      <b-col cols="5" md="5" class="pl-0">
                        {{year}}
                      </b-col>
                    </b-row>
                    <b-row no-gutters>
                      <b-col class="text-right mt-3" cols="8">
                        <b-button class="mt-2 ml-2" variant="primary" @click="createBudgetFromActualAmounts" :disabled="yearToActualAmounts === year">{{ $t(translationPath + 'table.btn_copy_all_amounts', { year_source: yearToActualAmounts, year_target: year }) }}</b-button>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
import titleMixins from '@/mixins/title'
import displayAccountTitle from '@/assets/filters/displayAccountTitle'
import CurrencyInput from '@/components/common/CurrencyInput'
import axios from 'axios'
import BudgetYearSelect from '@/components/common/BudgetYearSelect.vue'
import Loader from '@/components/common/Loader'

export default {
  name: 'BudgetMultipleAccountsTableIncomesExpenses',
  mixins: [titleMixins],
  components: { CurrencyInput, BudgetYearSelect, Loader },
  filters: { displayAccountTitle, formatAmount },
  props: ['activeTab'],
  data () {
    return {
      translationPath: 'budget.multiple_accounts.',
      tableItems: null,
      tableFilter: '',
      year: null,
      subTabs: 0,
      yearToCopyBudget: null,
      yearToActualAmounts: null,
      apiYears: null,
      budgetId: null,
      budgetIdOptionsModel: null,
      budgets: [],
      isLoaded: false
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    fieldValues () {
      return [
        { key: 'title', label: this.$t(this.translationPath + 'table.th_' + (this.currentUser.strict_accounting_mode ? 'accounts' : 'categories')), sortable: false },
        { key: 'currentYearBudget', label: this.$t(this.translationPath + 'table.th_current', { year: this.year }), sortable: false, class: 'text-right' },
        { key: 'period', label: this.$t(this.translationPath + 'table.th_period') },
        { key: 'newAmount', label: this.$t(this.translationPath + 'table.th_amount') },
        { key: 'newAnnualAmount', label: this.$t(this.translationPath + 'table.th_year_amount') },
        { key: 'actions', label: '', class: 'justify-content-lg-end align-items-center' }
      ]
    },
    periodOptions () {
      return [
        { value: 'per_month', text: this.$t(this.translationPath + 'table.per_month') },
        { value: 'per_year', text: this.$t(this.translationPath + 'table.per_year') }
      ]
    },
    budgetsOptions () {
      const opts = []
      this.budgets.map(budget => {
        opts.push({ text: budget.title, value: budget.id })
      })
      return opts
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    }
  },
  methods: {
    handleSubTabsClick (name) {
      this.budgetIdOptionsModel = this.budgetId

      let route = `/budget/accounts/${this.$route.params.tab}/${name}`
      if (this.$route.query.bid) {
        route += `?bid=${this.$route.query.bid}`
      }
      this.$router.push(route)
    },
    formatAmount (amount) {
      if (this.currentCOA.locale) {
        amount = formatAmount(
          amount,
          this.currentCOA.locale,
          this.currentCOA.currency,
          this.currentCOA.currency_iso,
          true,
          0
        )
      }
      return amount
    },
    rowClass (item) {
      if (!item) return ''
      if (item.is_account_group) return 'table-accountgroup'
      if (item.is_part_sum_row) return 'group_result_row'
      if (item.is_total_row) return 'total-sum'
      if (item.is_empty_row) return 'empty-row'
    },
    onBudgetChange (item) {
      if (!item.updateModel.amount) {
        item.updateModel.amount = 0
      }

      switch (item.updateModel.period) {
        case 'per_month':
          item.updateModel.yearAmount = item.updateModel.amount * 12
          break
        case 'per_year':
          item.updateModel.yearAmount = item.updateModel.amount
          break
      }
      this.recalculateTotals()
      this.$refs.budgetTable.refresh()
    },
    onSetBudgetAccount (item) {
      const monthValue = Math.floor((item.updateModel.yearAmount / 12) * 100) / 100

      const postData = {
        account_id: item.id,
        year: this.year,
        year_data: {},
        budget_id: this.budgetId
      }
      for (let month = 1; month <= 12; month++) {
        postData.year_data[month] = {
          month: month,
          amount: monthValue.toFixed(2)
        }
      }

      axios.post(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/year`, postData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.$bvToast.toast(this.$t(this.translationPath + 'update.success_msg_' + (this.currentUser.strict_accounting_mode ? 'account' : 'category'), { account_title: item.title }).toString(), {
              title: this.$t(this.translationPath + 'update.success_title').toString(),
              variant: 'success',
              solid: true
            })
            item.currentYearBudget = item.updateModel.yearAmount
          } else {
            throw new Error('Update failed')
          }
        })
        .catch((error) => {
          this.$bvToast.toast(this.$t(this.translationPath + 'update.failed_msg_' + (this.currentUser.strict_accounting_mode ? 'account' : 'category'), { account_title: item.title }).toString(), {
            title: this.$t(this.translationPath + 'update.failed_title').toString(),
            variant: 'danger',
            solid: true
          })
          console.error(error)
        })
    },
    onSetBudgetAccountGroup (item) {
      const postData = {
        data: []
      }
      item.accounts.map(account => {
        const monthValue = Math.floor((account.updateModel.yearAmount / 12) * 100) / 100
        const accountData = {
          account_id: account.id,
          year: this.year,
          year_data: {},
          budget_id: this.budgetId
        }
        for (let month = 1; month <= 12; month++) {
          accountData.year_data[month] = {
            month: month,
            amount: monthValue.toFixed(2)
          }
        }
        postData.data.push(accountData)
      })

      axios.post(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts-multiple`, postData)
        .then((response) => {
          if (response.status === 204 || response.status === 201) {
            this.$bvToast.toast(this.$t(this.translationPath + 'update.success_msg_' + (this.currentUser.strict_accounting_mode ? 'account_group' : 'category_group'), { account_group_title: item.title }).toString(), {
              title: this.$t(this.translationPath + 'update.success_title').toString(),
              variant: 'success',
              solid: true
            })
            item.accounts.map(account => {
              account.currentYearBudget = account.updateModel.yearAmount
            })
          } else {
            throw new Error('Update failed')
          }
        })
        .catch((error) => {
          this.$bvToast.toast(this.$t(this.translationPath + 'update.failed_msg_' + (this.currentUser.strict_accounting_mode ? 'account_group' : 'category_group'), { account_group_title: item.title }).toString(), {
            title: this.$t(this.translationPath + 'update.failed_title').toString(),
            variant: 'danger',
            solid: true
          })
          console.error(error)
        })
    },
    onSetBudgetAll () {
      const postData = {
        data: []
      }
      this.tableItems.filter(i => i.is_account).map(account => {
        const monthValue = Math.floor((account.updateModel.yearAmount / 12) * 100) / 100
        const accountData = {
          account_id: account.id,
          year: this.year,
          year_data: {},
          budget_id: this.budgetId
        }
        for (let month = 1; month <= 12; month++) {
          accountData.year_data[month] = {
            month: month,
            amount: monthValue.toFixed(2)
          }
        }
        postData.data.push(accountData)
      })

      axios.post(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts-multiple`, postData)
        .then((response) => {
          if (response.status === 204 || response.status === 201) {
            this.$bvToast.toast(this.$t(this.translationPath + 'update.success_msg_' + (this.currentUser.strict_accounting_mode ? 'account_all' : 'category_all')).toString(), {
              title: this.$t(this.translationPath + 'update.success_title').toString(),
              variant: 'success',
              solid: true
            })
            this.loadData(false)
          } else {
            throw new Error('Update failed')
          }
        })
        .catch((error) => {
          this.$bvToast.toast(this.$t(this.translationPath + 'update.failed_msg_account_category_all').toString(), {
            title: this.$t(this.translationPath + 'update.failed_title').toString(),
            variant: 'danger',
            solid: true
          })
          console.error(error)
        })
    },
    recalculateTotals () {
      const sumData = { currentAmount: 0, newAmount: 0, totalCurrentAmount: 0, totalNewAmount: 0 }
      this.tableItems.forEach((item) => {
        if (item.is_account) {
          sumData.currentAmount += parseFloat(item.currentYearBudget)
          sumData.newAmount += parseFloat(item.updateModel.yearAmount)

          sumData.totalCurrentAmount += parseFloat(item.currentYearBudget)
          sumData.totalNewAmount += parseFloat(item.updateModel.yearAmount)
        } else if (item.is_part_sum_row) {
          item.currentAmount = sumData.currentAmount
          item.newAmount = sumData.newAmount
          sumData.currentAmount = 0
          sumData.newAmount = 0
        } else if (item.is_total_row) {
          item.newAmount = sumData.totalNewAmount
          item.currentAmount = sumData.totalCurrentAmount
        }
      })
    },
    async loadData (handleLoaderIcon = true) {
      if (!this.activeTab || !this.year || !this.budgetId) {
        return false
      }

      if (handleLoaderIcon) {
        this.isLoaded = false
      }

      axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups?account_type=${this.activeTab.substr(0, this.activeTab.length - 1)}&limit=1000&include_ytd=1&year=${this.year}&log=budgetMultipleAccountsTableIncomesExpenses&budget_id=${this.budgetId}`)
        .then((response) => {
          const accountGroups = response.data.data
          this.tableItems = []
          const oAdditionalMetaData = { is_account_group: false, is_account: false, is_empty_row: false, is_part_sum_row: false, is_total_row: false }
          const oEmptyRow = { is_account_group: false, is_account: false, is_empty_row: true, is_part_sum_row: false, is_total_row: false }
          const oPartSumRow = { title: '', total: 0, is_account_group: false, is_account: false, is_empty_row: false, is_part_sum_row: true, is_total_row: false }
          const oTotalRow = { title: '', total: 0, is_account_group: false, is_account: false, is_empty_row: false, is_part_sum_row: false, is_total_row: true }
          let bFirst = true
          let iPartSum = 0

          // Iterate groups
          Object.keys(accountGroups).forEach((key) => {
            accountGroups[key] = Object.assign(accountGroups[key], oAdditionalMetaData)
            accountGroups[key].is_account_group = true

            if (bFirst) {
              bFirst = false
            } else {
              oPartSumRow.amount = iPartSum

              this.tableItems.push({ ...oPartSumRow })
              this.tableItems.push(oEmptyRow)

              iPartSum = 0
            }
            this.tableItems.push(accountGroups[key])

            // Iterate accounts
            Object.keys(accountGroups[key].accounts).forEach((iAccountId, keys) => {
              accountGroups[key].accounts[iAccountId].updateModel = {
                period: 'per_month',
                amount: parseFloat(accountGroups[key].accounts[iAccountId].currentYearBudget) / 12,
                yearAmount: parseFloat(accountGroups[key].accounts[iAccountId].currentYearBudget)
              }
              accountGroups[key].accounts[iAccountId] = Object.assign(accountGroups[key].accounts[iAccountId], oAdditionalMetaData)
              accountGroups[key].accounts[iAccountId].is_account = true

              this.tableItems.push(accountGroups[key].accounts[iAccountId])
            })
          })

          this.tableItems.push({ ...oPartSumRow })
          this.tableItems.push({ ...oEmptyRow })
          this.tableItems.push({ ...oTotalRow })
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isLoaded = true
        })
    },
    async loadBudgets () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/budgets`)
          .then(response => {
            this.budgets = response.data.data
            resolve(this.budgets)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    preselectBudget () {
      if (this.$route.query.bid) {
        const bid = Number(this.$route.query.bid)
        const validBids = []
        this.budgets.map(budget => {
          validBids.push(budget.id)
        })
        if (validBids.indexOf(bid) !== -1) {
          this.budgetId = Number(this.$route.query.bid)
          this.budgetIdOptionsModel = Number(this.$route.query.bid)
        }
      }

      if (!this.budgetId) {
        this.budgetId = this.currentCOA.default_budget_id
        this.budgetIdOptionsModel = this.currentCOA.default_budget_id
      }
    },
    onYearChanged (year) {
      this.year = year
      this.$emit('year', year)
    },
    copyBudget (item) {
      axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/${this.yearToCopyBudget}/${item.id}/sum?budget_id=${this.budgetId}`)
        .then(response => {
          if (item.updateModel.period === 'per_month') {
            item.updateModel.amount = response.data.data.total_budget_sum / 12
          } else {
            item.updateModel.amount = Number(response.data.data.total_budget_sum)
          }
          this.onBudgetChange(item)
          this.onSetBudgetAccount(item)
        })
        .catch(err => {
          console.error(err)
        })
    },
    createBudgetFromAnotherBudgetYear () {
      const postData = {
        account_types: [this.activeTab.substr(0, this.activeTab.length - 1)],
        year_source: this.yearToCopyBudget,
        year_target: this.year,
        budget_id: this.budgetId
      }

      axios.post(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/create/from-existing-budget-amounts`, postData)
        .then(response => {
          this.loadData()
          if (response.data.data.copy_status) {
            this.$bvToast.toast(this.$t(this.translationPath + 'copy.success_msg_' + (this.currentUser.strict_accounting_mode ? 'account' : 'category'), { year_source: this.yearToCopyBudget, year_target: this.year, type: this.activeTab.substr(0, this.activeTab.length - 1) }).toString(), {
              title: this.$t(this.translationPath + 'copy.success_title').toString(),
              variant: 'success',
              solid: true
            })
            this.handleSubTabsClick('create')
          } else {
            this.$bvToast.toast(this.$t(this.translationPath + 'copy.fail_msg_' + (this.currentUser.strict_accounting_mode ? 'account' : 'category')).toString(), {
              title: this.$t(this.translationPath + 'copy.fail_title').toString(),
              variant: 'danger',
              solid: true
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    createBudgetFromActualAmounts () {
      const postData = {
        account_types: [this.activeTab.substr(0, this.activeTab.length - 1)],
        year_source: this.yearToActualAmounts,
        year_target: this.year,
        budget_id: this.budgetId
      }

      axios.post(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/create/from-actual-amounts`, postData)
        .then(response => {
          this.loadData()
          if (response.data.data.status) {
            this.$bvToast.toast(this.$t(this.translationPath + 'copy_ytd.success_msg_' + (this.currentUser.strict_accounting_mode ? 'account' : 'category'), { year_source: this.yearToCopyBudget, year_target: this.year, type: this.activeTab.substr(0, this.activeTab.length - 1) }).toString(), {
              title: this.$t(this.translationPath + 'copy_ytd.success_title').toString(),
              variant: 'success',
              solid: true
            })
            this.handleSubTabsClick('create')
          } else {
            this.$bvToast.toast(this.$t(this.translationPath + 'copy_ytd.fail_msg_' + (this.currentUser.strict_accounting_mode ? 'account' : 'category')).toString(), {
              title: this.$t(this.translationPath + 'copy_ytd.fail_title').toString(),
              variant: 'danger',
              solid: true
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    detectTab (name) {
      if (name === 'options') return 1
      return 0
    },
    selectBudget () {
      if (this.budgetId === this.budgetIdOptionsModel) {
        return false
      }

      this.budgetId = this.budgetIdOptionsModel
      console.log(this.budgetId)
      this.$router.push({ query: { bid: this.budgetId } })
      this.loadYearsForComponent()
      this.loadData()
    },
    loadYearsForComponent () {
      axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/years-for-component?budget_id=${this.budgetId}`)
        .then(response => {
          this.apiYears = response.data.data
          if (this.apiYears.years_with_actual_transaction_values.length) {
            this.yearToActualAmounts = this.apiYears.years_with_actual_transaction_values[0]
          }
          if (this.apiYears.years_with_existing_budget_values.length) {
            this.yearToCopyBudget = this.apiYears.years_with_existing_budget_values[0]
          }
        })
    }
  },
  async created () {
    await this.loadBudgets()
    this.preselectBudget()
    this.loadYearsForComponent()
    this.loadData()
  },
  watch: {
    '$route.params.subtab': {
      handler: function (val) {
        if (val) {
          this.subTabs = this.detectTab(val)
        }
      },
      immediate: true,
      deep: true
    },
    'currentCOA.locale' () {
      if (!this.budgetId) {
        this.preselectBudget()
        this.loadYearsForComponent()
        this.loadData()
      }
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    tableItems: {
      deep: true,
      handler () {
        this.recalculateTotals()
      }
    },
    activeTab () {
      this.loadData()
    },
    year () {
      this.loadData()
    }
  }
}
</script>

<style lang="scss">
.budget-multiple-accounts-table-incomes-expenses {
  tr.empty-row td{
    background: white;
  }
  .flaticon.stroke.copy-1::before {
    content: "\e415";
  }
  .nav-tabs {
    .nav-item:first-child {
      margin-left: 35px;
    }
    .nav-link {
      font-size: 16px;
      color: #dda3a2;
    }
    .nav-link.active {
      background-color: transparent;
      font-weight: bold;
      border-bottom-color: white;
      color: black;
    }
  }
  .table {
    thead {
      th {
        border-top: none;
      }
    }
  }
}
</style>
