<template>
  <b-container class="report-advanced-budget bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <b-col sm="12" md="10" lg="11">
          <h1 class="text-left">{{ $t(translationPath + 'title') }} - {{ $t(translationPath + 'year') }}: {{ typeof this.year === 'string' ? this.$t(translationPath + 'amounts_filter.all_years').toLowerCase() : this.year }}</h1>
        </b-col>
      </b-row>

      <div class="advanced-budget-submenu-wrapper">
        <b-row class="submenu-container dark" no-gutters>
          <b-col md="auto">
            <b-link :to="'/budget/accounts/incomes/create' + ($route.query.bid ? ('?bid=' + $route.query.bid) : '')" :class="{ disabled: activeTab === 'incomes' }" @click="onTabMenuClick('incomes')">
              {{ $t(translationPath + 'menu.incomes') }}
            </b-link>
            <span class="mx-3 divider">|</span>
          </b-col>
          <b-col md="auto">
            <b-link :to="'/budget/accounts/expenses/create' + ($route.query.bid ? ('?bid=' + $route.query.bid) : '')" :class="{ disabled: activeTab === 'expenses' }" @click="onTabMenuClick('expenses')">
              {{ $t(translationPath + 'menu.expenses') }}
            </b-link>
            <span class="mx-3 divider">|</span>
          </b-col>
          <b-col md="auto">
            <b-link :to="'/budget/accounts/amounts' + ($route.query.bid ? ('?bid=' + $route.query.bid) : '')" :class="{ disabled: activeTab === 'amounts' }" @click="onTabMenuClick('amounts')">
              {{ $t(translationPath + 'menu.amounts') }}
            </b-link>
          </b-col>
        </b-row>
      </div>

      <budget-multiple-accounts-table-incomes-expenses
        v-if="['incomes', 'expenses'].indexOf(activeTab) !== -1"
        :active-tab="activeTab"
        @year="(data) => year = data"
      ></budget-multiple-accounts-table-incomes-expenses>

      <budget-multiple-accounts-table-amounts
        v-if="activeTab === 'amounts'"
        :year="year"
        @year="(data) => year = data"
      ></budget-multiple-accounts-table-amounts>
    </div>
  </b-container>
</template>

<script>
import BudgetMultipleAccountsTableIncomesExpenses from './BudgetMultipleAccountsTableIncomesExpenses.vue'
import BudgetMultipleAccountsTableAmounts from './BudgetMultipleAccountsTableAmounts.vue'

export default {
  name: 'BudgetMultipleAccounts',
  components: {
    BudgetMultipleAccountsTableIncomesExpenses,
    BudgetMultipleAccountsTableAmounts
  },
  data () {
    return {
      activeTab: 'incomes',
      translationPath: 'budget.multiple_accounts.',
      year: null
    }
  },
  computed: {
    breadcrumbItems () {
      return [
        { text: this.$t('common.header.header_menu.incomes_and_expenses'), to: '/incomes-expenses/' },
        { text: this.$t('common.header.header_menu.incomes_expenses.budget'), to: '/incomes-expenses/budget' },
        { text: '', active: true }
      ]
    }
  },
  methods: {
    onTabMenuClick (newTab) {
      this.activeTab = newTab
    }
  },
  async created () {
    if (['expenses', 'amounts'].indexOf(this.$route.params.tab) !== -1) {
      this.activeTab = this.$route.params.tab
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/submenu.scss';

.report-advanced-budget {
  .table_background {
    background-color: #fff;
  }
  .accounts-table a {
    color: #212529;
  }
  th {
    border-top: 1px solid #dee2e6;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .group_result_row {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
}
</style>
